@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100..900&display=swap");
:root {
  --border-radius-tiny: 4px;
  --border-radius-sm: 6px;
  --border-radius: 8px;
  --border-radius-md: 10px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
  --border-radius-2xl: 20px;
  --border-radius-full: 999px;
  --navbar-height: 56px;
  --input-height: 36px;
  --text-base: 14px;
  --page-head-height: 48px;
  --table-border-color: var(--gray-500);
}

[data-theme-mode=light] {
  --primary: oklch(0.6 0.118 184.704);
  --primary-dark: oklch(0.437 0.078 188.216);
  --primary-active: #2c965f;
  --primary-color: var(--primary);
  --primary-bg: var(--primary-dark);
  --primary-light-bg: #00353F;
  --primary-lighter-bg: var(--primary-bg);
  --primary-border: oklch(0.511 0.096 186.391);
  --primary-200: #004D5A;
  --border-primary: var(--primary);
  --btn-primary: var(--primary);
  --date-active-bg: var(--primary);
  --primary-shadow: 0 0 0 2px hsl(145 40% 85% / 1);
  --btn-primary-focus-shadow: var(--primary-shadow);
  --btn-primary-active-shadow: var(--primary-shadow);
  --checkbox-focus-shadow: var(--primary-shadow);
  --control-bg: #f3f4f6;
}

/* ---------- START GENERAL ---------- */
* {
  letter-spacing: 0 !important;
  scrollbar-color: unset;
}

body {
  font-family: "Noto Kufi Arabic", serif;
  font-style: normal;
  font-size: var(--text-base);
  letter-spacing: 0 !important;
}

.new-timeline {
  padding-top: 0 !important;
  margin-top: var(--margin-lg) !important;
  border-radius: var(--border-radius-md) !important;
  overflow: hidden;
}

html[data-theme-mode=light] .new-timeline {
  background-color: #fff !important;
}

.new-timeline .timeline-item {
  margin-bottom: 4px;
  margin-top: 4px;
}

.new-timeline .activity-title {
  padding: 0 15px !important;
}

.splash {
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--primary);
  box-shadow: 0 0 0 0 var(--primary);
  animation: pulsing 1.5s infinite linear;
  position: relative;
}

.splash:before,
.splash:after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  box-shadow: 0 0 0 0 var(--primary);
  animation: inherit;
  animation-delay: -0.5s;
}

.splash:after {
  animation-delay: -1s;
}

@keyframes pulsing {
  100% {
    box-shadow: 0 0 0 40px rgba(48, 166, 105, 0);
  }
}
.splash img {
  border-radius: 50%;
}

.page-head {
  margin-bottom: 0 !important;
}

.page-head .page-title .title-area .title-text {
  font-size: var(--text-xl) !important;
}

/* ---------- START GENERAL ---------- */
/* ---- Start Table ---- */
.list-row, .list-row-head {
  border-radius: var(--border-radius-sm);
}

.list-row-container .list-row-border {
  margin: 2px 10px;
}

/* ---- End Table ---- */
html[data-theme-mode=light] .form-control {
  background-color: #ffffff;
  border: 1px solid #d1d5dc;
}

html[data-theme-mode=light] .form-control:focus {
  box-shadow: 0 0 0 2px rgba(124, 124, 124, 0.05);
  background-color: #fff;
  border-color: #e2e2e2;
}

html[data-theme-mode=light] .btn-primary:focus,
html[data-theme-mode=light] div#driver-popover-item .driver-popover-footer button.driver-next-btn:focus,
html[data-theme-mode=light] .btn-primary.focus,
html[data-theme-mode=light] div#driver-popover-item .driver-popover-footer button.focus.driver-next-btn {
  box-shadow: var(--btn-primary-focus-shadow);
}

html[data-theme-mode=light] .btn.btn-primary:active,
html[data-theme-mode=light] div#driver-popover-item .driver-popover-footer button.btn-primary:active,
html[data-theme-mode=light] div#driver-popover-item .driver-popover-footer button.driver-next-btn:active {
  background-color: var(--primary-active) !important;
  box-shadow: var(--btn-primary-active-shadow) !important;
}

.datepicker--pointer {
  border-width: 2px !important;
}

html[data-theme-mode=light] .datepicker--cell.-selected-,
html[data-theme-mode=light] .datepicker--cell.-current-.-selected- {
  background: var(--date-active-bg);
}

.-bottom-left- .datepicker--pointer,
.-top-left- .datepicker--pointer {
  left: 16px !important;
}

.-bottom-center- .datepicker--pointer,
.-bottom-left- .datepicker--pointer,
.-bottom-right- .datepicker--pointer {
  transform: rotate(45deg) !important;
}

.-top-center- .datepicker--pointer, .-top-left- .datepicker--pointer, .-top-right- .datepicker--pointer {
  transform: rotate(225deg) !important;
}

html[data-theme-mode=light] .dropdown-menu {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.05), 0 4px 6px -4px rgba(0, 0, 0, 0.05);
}

.dropdown-item {
  border-radius: var(--border-radius-tiny);
}

.widget.onboarding-widget-box {
  border-radius: var(--border-radius-sm) !important;
}

.widget.onboarding-widget-box .widget-head .widget-control {
  margin-top: 0 !important;
}

html[dir=rtl] .widget-control .dropdown-menu.dropdown-menu-right.show {
  transform: translate3d(0px, 25px, 0px) !important;
}

.grid-heading-row {
  border-top-right-radius: var(--border-radius) !important;
  border-top-left-radius: var(--border-radius) !important;
}

.form-grid {
  border-radius: var(--border-radius) !important;
}

/* ---------- END GENERAL ---------- */
/* ---------- START NAVBAR ---------- */
.navbar {
  height: var(--navbar-height);
}

html[data-theme-mode=light] .navbar {
  background: var(--primary-lighter-bg) !important;
}

#navbar-breadcrumbs a:before {
  content: "/";
}

html[data-theme-mode=light] #navbar-breadcrumbs a,
html[data-theme-mode=light] #navbar-breadcrumbs li.disabled a {
  color: #fff;
}

html[data-theme-mode=light] .nav-link {
  color: #ffffff;
}

html[data-theme-mode=light] .navbar .vertical-bar {
  border-color: var(--primary-border);
}

html[data-theme-mode=light] input#navbar-search {
  background: #fff !important;
  border: 2px solid #fff;
}

.search-bar .awesomplete input {
  height: 32px;
}

html[data-theme-mode=light] .search-dialog .search-results .search-sidebar .standard-sidebar-item {
  border-radius: var(--border-radius-tiny);
}

html[data-theme-mode=light] .search-dialog .search-results .search-sidebar .standard-sidebar-item > a {
  color: #ffffff;
}

html[data-theme-mode=light] .search-dialog .search-results .search-sidebar .standard-sidebar-item svg.es-icon {
  stroke: #ffffff !important;
}

html[data-theme-mode=light] .search-dialog .search-results .search-sidebar .standard-sidebar-item:hover,
html[data-theme-mode=light] .search-dialog .search-results .search-sidebar .standard-sidebar-item:focus,
html[data-theme-mode=light] .search-dialog .search-results .search-sidebar .standard-sidebar-item:focus-within {
  background: #4DAEA0 !important;
}

html[data-theme-mode=light] .notifications-list .notification-list-header {
  border-bottom: 2px solid var(--border-color) !important;
}

html[data-theme-mode=light] .notifications-list .notification-item-tabs .notifications-category.active,
html[data-theme-mode=light] .form-tabs-list .form-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid var(--primary-color) !important;
  color: var(--primary);
}

html[data-theme-mode=light] .notifications-list .notification-item-tabs .notifications-category {
  margin-bottom: -2px !important;
}

/* ---------- END NAVBAR ---------- */
/* ---------- START SIDEBAR ---------- */
.standard-sidebar-item .item-anchor {
  padding: 6px 8px !important;
}

html[data-theme-mode=light] .form-sidebar .sidebar-menu,
html[data-theme-mode=light] .form-sidebar .sidebar-menu.text-muted,
html[data-theme-mode=light] .layout-side-section .checkbox .label-area,
html[data-theme-mode=light] .layout-side-section .control-label {
  color: #fff !important;
}

html[data-theme-mode=light] .form-sidebar .sidebar-menu .btn-link {
  background-color: var(--btn-primary);
}

html[data-theme-mode=light] .form-sidebar .sidebar-menu svg {
  filter: invert(1);
}

html[data-theme-mode=light] .standard-sidebar-section > .sidebar-item-container {
  border-bottom: 1px solid var(--primary-border);
}

.sidebar-item-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 4px;
}

html[data-theme-mode=light][dir=rtl] .sidebar-child-item.nested-container {
  padding-right: 8px;
  margin-right: 18px;
  border-right: 1px solid var(--primary-border);
}

html[data-theme-mode=light][dir=ltr] .sidebar-child-item.nested-container {
  padding-left: 8px;
  margin-left: 18px;
  border-left: 1px solid var(--primary-border);
}

html[data-theme-mode=light] .sidebar-item-container .sidebar-item-container:not(:last-child) {
  border-bottom: 1px solid var(--primary-border) !important;
}

.layout-side-section {
  padding-top: 15px !important;
  padding-left: 15px !important;
}

html[data-theme-mode=light] .layout-side-section,
html[data-theme-mode=light] .layout-side-section .overlay-sidebar.opened {
  background: var(--primary-bg) !important;
  scrollbar-color: var(--primary-border) transparent !important;
}

html[data-theme-mode=light] .layout-side-section a:not(a.btn),
html[data-theme-mode=light] .layout-side-section .sidebar-label,
html[data-theme-mode=light] .user-profile-sidebar .profile-details .detail-item.user-stats-detail h5,
html[data-theme-mode=light] .user-profile-sidebar .profile-details .user-stats-item .stat-label,
html[data-theme-mode=light] .user-profile-sidebar .profile-details .user-stats-item .stat-value {
  color: #fff;
}

.standard-sidebar .standard-sidebar-item,
.desk-sidebar .standard-sidebar-item {
  border-radius: var(--border-radius-tiny);
  margin-bottom: 4px;
}

.standard-sidebar .standard-sidebar-item,
.desk-sidebar .standard-sidebar-item,
.search-dialog .search-results .search-sidebar .standard-sidebar-item {
  letter-spacing: 0 !important;
}

html[data-theme-mode=light] .standard-sidebar .standard-sidebar-item:hover,
html[data-theme-mode=light] .standard-sidebar .standard-sidebar-item:focus,
html[data-theme-mode=light] .standard-sidebar .standard-sidebar-item:focus-within,
html[data-theme-mode=light] .desk-sidebar .standard-sidebar-item:hover,
html[data-theme-mode=light] .desk-sidebar .standard-sidebar-item:focus,
html[data-theme-mode=light] .desk-sidebar .standard-sidebar-item:focus-within {
  background: var(--primary) !important;
}

html[data-theme-mode=light] .standard-sidebar .standard-sidebar-item:hover a,
html[data-theme-mode=light] .standard-sidebar .standard-sidebar-item:focus a,
html[data-theme-mode=light] .standard-sidebar .standard-sidebar-item:focus-within a,
html[data-theme-mode=light] .desk-sidebar .standard-sidebar-item:hover a,
html[data-theme-mode=light] .desk-sidebar .standard-sidebar-item:focus a,
html[data-theme-mode=light] .desk-sidebar .standard-sidebar-item:focus-within a,
html[data-theme-mode=light] .search-dialog .search-results .search-sidebar .standard-sidebar-item:hover a,
html[data-theme-mode=light] .search-dialog .search-results .search-sidebar .standard-sidebar-item:focus a,
html[data-theme-mode=light] .search-dialog .search-results .search-sidebar .standard-sidebar-item:focus-within a {
  color: #fff;
}

html[data-theme-mode=light] .standard-sidebar-label,
html[data-theme-mode=light] .standard-sidebar .standard-sidebar-item > a,
html[data-theme-mode=light] .desk-sidebar .standard-sidebar-item > a {
  color: #fff;
}

html[data-theme-mode=light] .standard-sidebar-label svg,
html[data-theme-mode=light] .standard-sidebar .standard-sidebar-item svg.es-icon,
html[data-theme-mode=light] .desk-sidebar .standard-sidebar-item svg.es-icon {
  stroke: #fff !important;
}

html[data-theme-mode=light] .sidebar-item-icon,
html[data-theme-mode=light] .navbar-nav .nav-link svg {
  filter: invert(100%);
}

html[data-theme-mode=light] .standard-sidebar-label svg {
  stroke: var(--primary);
}

html[data-theme-mode=light] .standard-sidebar .standard-sidebar-item.selected,
html[data-theme-mode=light] .desk-sidebar .standard-sidebar-item.selected,
html[data-theme-mode=light] .search-dialog .search-results .search-sidebar .standard-sidebar-item.selected {
  background: var(--primary) !important;
}

html[data-theme-mode=light] .sidebar-item-container:has(.sidebar-child-item .selected) > .desk-sidebar-item.standard-sidebar-item {
  background-color: var(--primary);
}

html[data-theme-mode=light] .standard-sidebar .standard-sidebar-item.selected > a,
html[data-theme-mode=light] .desk-sidebar .standard-sidebar-item.selected > a,
html[data-theme-mode=light] .search-dialog .search-results .search-sidebar .standard-sidebar-item.selected > a {
  color: #fff;
}

/* ---------- END SIDEBAR ---------- */
/* ---------- START MAIN ---------- */
body.full-width .container-xl,
body.full-width .container-lg,
body.full-width .container-md,
body.full-width .container-sm,
body.full-width .container {
  width: 100%;
  max-width: 100%;
}

.layout-main-section-wrapper {
  padding-top: 16px !important;
  padding-bottom: 12px !important;
  margin-top: 0px !important;
}

html[data-theme-mode=light] .layout-main-section-wrapper {
  background: #fff;
  border-top: 1px solid var(--border-color);
}

.layout-main-section {
  padding: 0 !important;
}

html[data-theme-mode=light] .layout-main-section {
  background-color: transparent !important;
  border: none !important;
}

html[data-theme-mode=light] .layout-main-section.frappe-card {
  background-color: #fff !important;
}

.layout-main-section.frappe-card .page-form .standard-filter-section {
  align-items: center;
}

html[data-theme-mode=light] .list-sidebar .list-filters .filter-pill .filter-name {
  color: var(--text-muted) !important;
}

html[data-theme-mode=light] .list-sidebar .list-filters .frappe-control .control-input-wrapper .help-box {
  color: #fff !important;
}

.layout-main-section.frappe-card .page-form .form-group {
  margin: 0;
}

@media (max-width: 767px) {
  html[data-theme-mode=light] .layout-main-section.frappe-card .page-form {
    flex-direction: column;
  }
  .layout-main-section.frappe-card .page-form .form-group {
    margin: 5px 0px;
  }
}
.widget.shortcut-widget-box {
  align-items: unset;
}

.widget.links-widget-box {
  padding: 0;
  overflow: hidden;
}

html[data-theme-mode=light] .widget.links-widget-box,
html[data-theme-mode=light] .widget.dashboard-widget-box {
  border: 1px solid rgb(226, 232, 240);
  box-shadow: rgba(28, 35, 50, 0.04) 0px 0.61146px 6.72604px 0px;
}

.widget.links-widget-box .widget-head {
  justify-content: center;
}

.widget.links-widget-box .widget-body {
  padding: 12px;
}

.widget.shortcut-widget-box,
.widget.links-widget-box .widget-body .link-item {
  border-radius: var(--border-radius-sm);
  padding: var(--padding-sm) var(--padding-md);
}

html[data-theme-mode=light] .widget.shortcut-widget-box,
html[data-theme-mode=light] .widget.links-widget-box .widget-body .link-item {
  border: 1px solid var(--border-color);
}

.widget.links-widget-box .widget-body .link-item:hover,
html[data-theme-mode=light] .widget.shortcut-widget-box:hover {
  border-color: var(--primary) !important;
}

html[data-theme-mode=light] .widget.links-widget-box .widget-head {
  background: rgba(0, 150, 137, 0.1);
  padding: 8px 12px;
}

html[data-theme-mode=light] .widget.links-widget-box .widget-head .widget-label .widget-title {
  color: var(--primary) !important;
}

html[dir=rtl] .widget.links-widget-box .link-item {
  margin-right: 0 !important;
}

html[dir=ltr] .widget.links-widget-box .link-item {
  margin-left: 0 !important;
}

html[data-theme-mode=light] .widget.links-widget-box .link-item {
  margin-bottom: 4px;
}

html[data-theme-mode=light] .widget.links-widget-box .link-item:first-of-type {
  margin-top: 0 !important;
}

.widget.links-widget-box .link-item .link-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.widget.links-widget-box .link-item .link-content svg {
  margin: 0;
}

html[data-theme-mode=light] .widget.number-widget-box .widget-body .widget-content .number {
  color: var(--primary-dark);
}

html[data-theme-mode=light] .widget.number-widget-box {
  border: 1px solid rgba(0, 150, 137, 0.2) !important;
  background-color: rgba(0, 150, 137, 0.05) !important;
  box-shadow: 0 5px 12px -10px rgba(0, 95, 90, 0.4), 0 4px 6px -4px rgba(0, 95, 90, 0.14) !important;
}

.widget,
.btn,
.page-actions .btn,
.page-actions div#driver-popover-item .driver-popover-footer button,
div#driver-popover-item .driver-popover-footer .page-actions button {
  border-radius: var(--border-radius-sm);
}

html[data-theme-mode=light] .btn.btn-primary,
html[data-theme-mode=light] div#driver-popover-item .driver-popover-footer button.btn-primary,
html[data-theme-mode=light] div#driver-popover-item .driver-popover-footer button.driver-next-btn {
  background-color: var(--btn-primary);
}

.form-footer .scroll-to-top {
  bottom: 0;
}

/* ---------- END MAIN ---------- */
/* ---------- START PRINT FORMATE ---------- */
.print-format .table-bordered,
.print-format .table-bordered {
  border-color: #5f5f5f !important;
}

/* ---------- END PRINT FORMATE ---------- */
@font-face {
  font-family: "marvel-saudi-riyal-symbol";
  src: url("/assets/theme/fonts/marvel-saudi-riyal-symbol.eot?1erur8");
  src: url("/assets/theme/fonts/marvel-saudi-riyal-symbol.eot?1erur8#iefix") format("embedded-opentype"), url("/assets/theme/fonts/marvel-saudi-riyal-symbol.ttf?1erur8") format("truetype"), url("/assets/theme/fonts/marvel-saudi-riyal-symbol.woff?1erur8") format("woff"), url("/assets/theme/fonts/marvel-saudi-riyal-symbol.svg?1erur8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  font-family: "marvel-saudi-riyal-symbol" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-inline: 3px !important;
}

.icon-saudi-riyal-symbol:before {
  content: "\e900";
}